html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100%;
  position: fixed;
  font-size: 16px;
  touch-action: none;
  font-family: PingFangTC-Regular, sans-serif;
  // iOS Safari tap background
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
*,
*:after,
*:before {
  box-sizing: border-box;
}
#root {
  height: 100%;
  background: linear-gradient(to bottom, #eee, #efe9e9);
}
a {
  text-decoration: none;
}
